@import '../../../../scss/theme-bootstrap';

.sd-search-gnav-input-field .elc-spinner-wrapper {
  height: 30px;
  width: 30px;
  .elc-spinner-container > div {
    background-color: transparent;
  }
}

.header-gnav-stardust-search-2020-elc-nodeblock,
.header-gnav-stardust-search-elc-nodeblock {
  height: 0;
}

.search-overlay-displayed {
  overflow: hidden;
  .page-navigation.active-menu-toogle {
    display: none;
  }
  .sd-search-gnav-input-field .elc-search-icon-wrapper .elc-search-icon {
    visibility: hidden;
  }
  .page-wrapper .page-header {
    background-color: $color-white;
  }
}

.sd-search-gnav-input-field {
  margin-#{$ldirection}: 45px;
  padding-top: 12px;
  width: fit-content;
  @media #{$medium-up} {
    margin-#{$ldirection}: 0;
    padding-top: 0;
    position: relative;
    #{$ldirection}: -50px;
    margin-right: 1px;
  }
  @media #{$xlarge-up} {
    #{$ldirection}: -64px;
    margin-right: 2px;
  }

  .elc-search-icon-wrapper {
    .elc-search-icon {
      background-color: $color-navy;
      @include svg-icon-inline-mask('search.svg');
    }
    .elc-gnav-search-button {
      position: absolute;
      #{$ldirection}: -12px;
      @media #{$medium-up} {
        position: relative;
        #{$ldirection}: -5px;
      }
      @media #{$xlarge-up} {
        #{$ldirection}: -3px;
      }
    }
    @media #{$medium-up} {
      border-#{$ldirection}: 1px solid $color-light-gray;
      padding: 0 14px;
      height: 52px;
    }
  }
  .elc-search-active-screen-wrapper {
    #{$ldirection}: 0;
    background-color: $color-black-transparent;
    height: 100vh;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    @media #{$large-up} {
      top: $header-with-searchbar-item-height-without-ad-banner;
      overflow: hidden;
    }
    .elc-search-gnav-box {
      background-color: $color-white;
      border-bottom: unset;
      height: auto;
      padding: 34px 20px 15px;
      @media #{$medium-up} {
        height: 105px;
        padding: 8px 19px 20px;
      }
      .elc-search-close-icon-wrapper {
        #{$rdirection}: 13px;
        position: absolute;
        top: 13px;
        @media #{$medium-up} {
          position: fixed;
          top: 22px;
          #{$rdirection}: 71px;
        }
        @media #{$xlarge-up} {
          #{$rdirection}: 83px;
        }
        .elc-search-close-icon {
          height: 20px;
          width: 20px;
          @media #{$medium-up} {
            height: 24px;
            width: 24px;
          }
        }
      }
      .elc-input-search-field {
        background-color: $color-white;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid $color-navy;
        color: $color-navy;
        font-family: $optimabook;
        font-size: 22px;
        height: 56px;
        padding: 0;
        @media #{$medium-up} {
          flex-grow: 1;
          width: unset;
        }
        &::placeholder {
          color: $color-medium-gray-alt;
          font-family: $optimabook;
          font-size: 22px;
          text-transform: none;
        }
      }
      .elc-search-results-button {
        @include form-submit-button;
        bottom: #{$sticky-footer-height-mobile};
        height: 80px;
        line-height: 56px;
        z-index: 2;
        &::after {
          content: '';
          border: 17px solid $color-white;
          bottom: 1px;
          position: absolute;
          top: 1px;
          width: 100%;
        }
        @media #{$medium-up} {
          height: 44px;
          &::after {
            border: none;
            border-top: 1px solid $color-navy;
            bottom: unset;
            top: 63px;
            width: 164px;
          }
        }
      }
    }
    .elc-search-enhanced {
      &-wrapper {
        @include swap_direction(padding, 0 0 150px 0);
        background-color: $color-white;
        @media #{$medium-up} {
          padding: 0;
          margin: 0;
        }
      }
      &-trending-list {
        background-color: $color-white;
        @media #{$medium-up} {
          margin-top: 10px;
        }
        .elc-search-trending-term {
          padding: 5px 0;
          &__link:hover {
            text-decoration: underline;
          }
        }
      }
      &-grid-title,
      &-trending-title {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.15em;
      }
      &-grid-wrapper {
        background-color: $color-white;
        max-width: unset;
        .elc-search-enhanced-grid-title {
          @include swap_direction(margin, 0 0 0 19px);
          @media #{$medium-up} {
            margin: 0;
          }
        }
      }
      &-trending-terms-wrapper {
        margin-bottom: 10px;
        margin-#{$ldirection}: 19px;
        @media #{$medium-up} {
          margin-bottom: 0;
          width: 16%;
        }
      }
    }
    .elc-search-enhanced-grid-wrapper,
    .elc-search-results-wrapper {
      @include swap_direction(padding, 0 0 150px 0);
      background-color: $color-white;
      margin-bottom: 94px;
      width: 100%;
      @media #{$medium-up} {
        column-gap: unset;
        margin-bottom: 0;
        max-width: unset;
        min-height: auto;
        padding: 0 48px 20px 19px;
      }
      .elc-product-brief-view {
        @media #{$medium-up} {
          grid-gap: 3%;
          grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
        }
        @media #{$xlarge-up} {
          grid-gap: 7%;
        }
      }
      .elc-search-has-no-suggestions {
        .elc-product-brief-view {
          @media #{$medium-up} {
            grid-gap: unset;
          }
        }
      }
      .elc-service-view-renderer {
        grid-area: unset;
      }
      .elc-grid-item-product {
        @media #{$medium-up} {
          padding: 0;
          width: 140px;
        }
        @media #{$xlarge-up} {
          padding: 0;
          width: 191px;
        }
        @media #{$xxlarge-up} {
          width: 232px;
        }
      }
      .elc-product-images-wrapper,
      .elc-img {
        @media #{$medium-up} {
          height: 214px;
        }
        @media #{$xlarge-up} {
          height: 278px;
        }
        @media #{$xxlarge-up} {
          height: 319px;
        }
      }
    }
    .elc-search-enhanced-grid-wrapper {
      padding: 0;
      @media #{$medium-up} {
        margin-bottom: 20px;
        width: 76%;
      }
    }
  }
}

.page-wrapper-checkout {
  .sd-search-gnav-input-field {
    margin-#{$ldirection}: 56px;
    @media #{$large-up} {
      margin-#{$ldirection}: 0;
    }
  }
}

.elc-search-results-message-wrapper {
  padding: 0;
  margin-#{$ldirection}: 19px;
  @media #{$medium-up} {
    margin-#{$ldirection}: 0;
  }
  .elc-search-no-result-message,
  .elc-search-result-adjustment-message {
    font-family: $akzidenz;
    font-size: 14px;
    margin: 0;
    .elc-search-term-wrapper {
      font-weight: normal;
    }
  }
  .elc-heading--5 {
    font-family: $optimabook;
    font-size: 23px;
    text-align: #{$ldirection};
    text-transform: capitalize;
  }
}

.elc-search-suggestion-container {
  @include swap_direction(margin, 0 0 10px 19px);
  @media #{$medium-up} {
    margin: 0;
  }
  .elc-search-typeahead-count,
  .elc-search-suggestion-link {
    color: $color-navy;
    font-family: $akzidenz;
    font-size: 15px;
  }
  .elc-search-suggestion-link {
    text-decoration: none;
    white-space: normal;
    &:hover {
      text-decoration: underline;
    }
  }
  .elc-search-typeahead-count {
    padding-#{$ldirection}: 5px;
  }
  .elc-search-typeahead-title {
    font-family: $akzidenz;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.15em;
    margin-bottom: 15px;
    padding: 0;
    text-transform: capitalize;
  }
  .elc-search-suggestion-wrapper {
    padding: 0;
  }
  .elc-search-suggestion-icon-wrapper {
    padding-#{$rdirection}: 10px;
  }
  .elc-search-suggestion-value {
    border: none;
    padding: 0 0 10px 0;
  }
  .elc-search-value {
    position: absolute;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 1.8;
  }
}

.elc-search-active-screen-wrapper {
  .elc-grid-container,
  .elc-search-result-overlay-message {
    font-size: 14px;
    margin: 0;
    @media #{$medium-up} {
      line-height: 1;
      margin: 5px 0 0;
      min-height: auto;
    }
  }
  .elc-search-results-message-skeleton,
  .elc-search-grid-skeleton {
    padding: 0 8px;
  }
  .elc-search-suggestions-skeleton-item {
    padding-#{$rdirection}: 8px;
  }
}

.elc-search-sort-mobile-wrapper select {
  text-align: $rdirection;
  text-align-last: $rdirection;
}

.elc-voice-search-wrapper {
  position: absolute;
  z-index: 1000;
  #{$rdirection}: 5px;
  top: 34px;
  @media #{$medium-up} {
    top: 8px;
    #{$rdirection}: 15px;
  }
  h2 {
    font-family: $optimabook;
    font-size: 24px;
    margin: 15px 0;
  }
  p {
    font-family: $optimabook;
    font-size: 14px;
    letter-spacing: normal;
    margin: 0;
    &.elc-voice-search-term {
      text-align: center;
      text-transform: capitalize;
    }
  }
  .elc-search-voice-transcoder {
    padding: 15.5px 15px;
  }
  .elc-search-microphone-wrapper {
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
  }
  .elc-search-speak-wrapper {
    background-color: $color-white;
    padding: 50px 16px;
  }
  .elc-searching-wrapper,
  .elc-error-capture-voice-wrapper,
  .elc-listen-message-wrapper,
  .elc-heading-allow-microphone {
    padding: 0 20px;
  }
  .elc-search-speak-icon-wrapper {
    &:after {
      background-color: $color-white;
      content: '';
      display: block;
      height: 200px;
      opacity: 0.75;
      position: absolute;
      top: 0;
      width: 200px;
      @include voiceMicrophoneAnimation;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      border: 3px solid $color-navy;
      border-radius: 50%;
    }
  }
  .elc-search-speak-microphone-icon,
  .elc-microphone-icon {
    background: url($svg-path + 'microphone.svg') center no-repeat;
    mask: none;
    -webkit-mask: none;
  }
  .elc-microphone-icon {
    height: 25px;
    -webkit-mask-size: 25px;
    width: 25px;
  }
  .elc-search-speak-microphone-icon {
    width: 100px;
    height: 100px;
  }
  .elc-speak-now-message {
    position: absolute;
    bottom: 100px;
    #{$ldirection}: 0;
    right: 0;
    text-align: center;
  }
}

.site-banner__is-open .sd-search-gnav-input-field {
  .elc-search-active-screen-wrapper {
    @media #{$large-up} {
      background-color: $color-white;
      top: $header-with-searchbar-height-without-nav;
    }
    .elc-search-gnav-box {
      .elc-search-close-icon-wrapper {
        @media #{$medium-up} {
          top: 22px;
        }
        @media #{$large-up} {
          top: 57px;
        }
      }
    }
  }
}
